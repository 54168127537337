import { Icon } from "office-ui-fabric-react";
import * as React from "react";

export const Warning = () => (
  <Icon iconName="Warning" style={{ fontSize: 80, color: "red" }} />
);

export const Info = () => (
  <Icon iconName="Info" style={{ fontSize: 80, color: "blue" }} />
);

export const CheckMark = () => (
  <Icon iconName="CheckMark" style={{ fontSize: 80, color: "green" }} />
);
