import React, { useState, useEffect } from "react";
import { getAlerts } from "../helper/AlertService.api";
import { AlertItem } from "./AlertItem";

export const AlertList = () => {
  const [Alerts, setAlerts] = useState([]);

  const handleAlerts = async () => {
    setAlerts(await getAlerts());
    setInterval(async () => {
      setAlerts(await getAlerts());
    }, 60000);
  };
  useEffect(() => {
    handleAlerts();
    return () => {
      setAlerts([]);
    };
  }, []);

  return (
    <div>
      {Alerts.length > 0 ? (
        <div>
          {Alerts.map((item, index) => (
            <AlertItem key={index} alert={item} />
          ))}
        </div>
      ) : (
        <h1>There are no alerts in progress.</h1>
      )}
    </div>
  );
};
