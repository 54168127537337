import React from "react";
import { Stack, Text, FontWeights, Fabric } from "office-ui-fabric-react";
import { AlertList } from "./components/AlertList";

const boldStyle = { root: { fontWeight: FontWeights.semibold } };

export const App: React.FunctionComponent = () => {
  return (
    <Fabric>
      <Text>
        <Stack
          horizontalAlign="center"
          verticalAlign="center"
          styles={{
            root: {
              width: "960px",
              margin: "0 auto",
              color: "#605e5c"
            }
          }}
          gap={15}
        >
          <img
            src="/rsc-logo.png"
            height="200px"
            alt="Rugby School Logo"
            style={{ margin: 40 }}
          />
          <Text variant="mega" styles={boldStyle} style={{ marginBottom: 20 }}>
            Alert Service
          </Text>
          <Text variant="large" style={{ marginBottom: 20 }}>
            The alert service provides up to date information in the event a
            critical incident.
          </Text>
          <Stack gap={15}>
            <AlertList />
          </Stack>
        </Stack>
      </Text>
    </Fabric>
  );
};
