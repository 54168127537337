import axios from "axios";
import { Alert } from "../models/Alert.model";

const url =
  "https://prod-05.uksouth.logic.azure.com:443/workflows/9a16260cb486468b84da5c253f0b0b81/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=GiOkZ6hGnU1vYEWQWL9oWQGoXQS4WCKL1wxEMZSNAzU";

export const getAlerts = async () => {
  const results = await axios.get(url);
  const alerts = results.data.map((item: Alert) => {
    const Alert: Alert = {
      Title: item.Title,
      Message: item.Message,
      Created: new Date(item.Created),
      Posted: new Date(item.Posted),
      Author: item.Author,
      Purpose: item.Purpose
    } as Alert;
    return Alert;
  });
  return alerts.sort((a: Alert, b: Alert) => (a.Created < b.Created ? 1 : -1));
};
