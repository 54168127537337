import React from "react";
import { Depths } from "@uifabric/fluent-theme/lib/fluent/FluentDepths";
import { Text } from "office-ui-fabric-react/lib/Text";
import { Alert } from "../models/Alert.model";
import { Warning, Info } from "../helper/Alert.Icons";

export const AlertItem: React.FC<{ alert: Alert }> = props => {
  const { Title, Message } = props.alert;

  return (
    <div
      style={{
        boxShadow: Depths.depth16,
        width: 800,
        minHeight: 200,
        marginBottom: 40,
        backgroundColor: "white"
      }}
    >
      <div style={{ marginLeft: 20, marginRight: 20, marginBottom: 20 }}>
        <Text
          variant="superLarge"
          nowrap
          block
          style={{ textAlign: "center", paddingTop: 20, paddingBottom: 20 }}
        >
          {Title}
        </Text>

        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <div style={{ paddingRight: 10 }}>
            <Info />
          </div>

          <div style={{ marginBottom: 40 }}>
            <Text variant="large" style={{ whiteSpace: "pre-wrap" }}>
              {Message}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};
